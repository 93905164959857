import React from "react";

const AboutUs = () => {
  return (
    <section className="">
      <h1 className="title">Over ons</h1>
      <p>
        Welkom op de website van Mimo’s Bakery. Mimo’s Bakery is gevestigd in
        Leidsche Rijn en bakt huisgemaakte swiss rolls in verschillende smaken.
        Een swiss roll, ook bekend als cake roll, is een luchtige opgerolde
        biscuit cake gevuld met licht gezoete slagroom. <br />
        Heerlijk voor speciale gelegenheden, zoals een verjaardag, high tea,
        baby shower of gewoon bij de koffie en thee.
        <br />
        Kortom, de swiss roll is een pure verwennerij voor elke gelegenheid. Ze
        zijn met liefde gemaakt en dat proef je.
        <br />
        Kijk in het menu voor ons aanbod.
      </p>
    </section>
  );
};

export default AboutUs;
