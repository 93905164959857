import React from "react";

const Reviews = () => {
  return (
    <section>
      <h1 className="title">Reviews</h1>
      <p className="mb-4">
        <a href="https://g.page/mimosbakery/review?rc">Bekijk of plaats</a> een
        review op onze Google pagina!
      </p>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2451.199444694382!2d5.038699699999999!3d52.094301699999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c66f89672aaa83%3A0xb82871f84d0fc24c!2sMimo&#39;s%20Bakery!5e0!3m2!1sen!2snl!4v1674347945394!5m2!1sen!2snl"
        width={600}
        height={450}
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Maps"
      ></iframe>
    </section>
  );
};

export default Reviews;
