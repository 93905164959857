import React from "react";

const CircleButton = ({ children, onClick, classNames = "" }) => {
  return (
    <button
      className={`flex items-center justify-center w-12 h-12 rounded-full bg-white shadow-md cursor-pointer ${classNames} hover:brightness-90 transition-all`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default CircleButton;
