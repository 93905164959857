import React from "react";
import Button from "../ui/Button";
import { BiPlus } from "react-icons/bi";
import { cartAtom, productDataAtom } from "../../App";
import { useAtom } from "jotai";
import { toast } from "react-hot-toast";
import { useAtomValue } from "jotai";

const Menu = () => {
  return (
    <section className="">
      <h1 className="title">Menu</h1>
      <p>
        Hieronder kunt u ons assortiment zien. De swiss rolls zijn opgedeeld in
        drie categorieën: Classic / Special / Kawaii.
      </p>
      <p className="font-medium text-primary-700">
        ⚠️ Maximaal 2 swiss rolls per bestelling.
      </p>
      <p className="font-medium text-yellow-700">
        ⚠️ Bestel minimaal 2 dagen van tevoren.
      </p>

      <div className="mt-10 flex flex-col space-y-16">
        <ProductSection
          type="classic"
          title="Classic"
          borderColor={"border-primary-400/60"}
        />
        <ProductSection
          type="special"
          title="Special"
          borderColor={"border-orange-400/40"}
        />
        <ProductSection
          type="kawaii"
          title="Kawaii"
          description={
            <p className="px-4 pb-4">
              Alle kawaii swiss rolls worden in 5 grote stukken gesneden. Kawaii
              swiss rolls worden met extra zorg gemaakt. Ophalen kan daarom
              alleen op zaterdag of zondag, andere dagen in overleg. Bestel je
              kawaii swiss roll <i>minimaal 1 week van tevoren</i>. Maximaal 1
              kawaii swiss roll per bestelling.
            </p>
          }
          borderColor={"border-pink-400/60"}
        />
        <ProductSection
          type="caketopper"
          title="Cake Toppers"
          borderColor={"border-slate-400/40"}
        />
        {/* <ProductSection
          type="cookie"
          title="Cookies"
          description={
            <p className="px-4 pb-4">
              Wil je meer dan 2 cookies bestellen? Neem dan via WhatsApp contact
              op.
            </p>
          }
          borderColor={"border-red-400/60"}
        /> */}
      </div>
    </section>
  );
};

const ProductSection = ({ type, title, description, borderColor }) => {
  const data = useAtomValue(productDataAtom);

  return (
    <div className={`md:border-l-2 md:pl-5 ${borderColor}`}>
      <h2 className="text-3xl p-4">{title}</h2>
      {description}
      <div className="md:flex items-start flex-wrap">
        {data
          .filter((item) => item.type === type)
          .map((item) => (
            <div className="md:basis-1/2 lg:basis-1/3 p-4" key={item._id}>
              <ProductCard
                name={item.name}
                description={item.description}
                price={item.price}
                id={item._id}
                image={item.image}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

const ProductCard = ({ name, description, price, id, image }) => {
  const [cart, setCart] = useAtom(cartAtom);
  const data = useAtomValue(productDataAtom);

  return (
    <>
      <div
        className="p-5 shadow-primary-600/10 shadow-lg border-[1px] 
        bg-primary-400/2 border-primary-600/30 hover:border-primary-500/50 
        hover:shadow-primary-500/20 transition-all rounded-md min-h-[450px] flex flex-col"
      >
        <img
          className="rounded mb-4"
          src={image}
          alt="Afbeelding niet beschikbaar"
        />
        <h3 className="text-lg">{name}</h3>
        <p className="mb-4 text-base">{description}</p>
        <p className="font-[Inter] text-lg font-semibold grow text-primary-800 mb-4">
          &euro;{price.toFixed(2)}
        </p>

        {/* <Button
          onClick={() => {
            const product = data.find((s) => s._id === id);
            if (!product) {
              toast.error(
                "Product niet gevonden, neem contact op met de eigenaar."
              );
              return;
            }

            const swissrollCount = cart.items.filter(
              (item) => data.find((s) => s._id === item)?.type !== "cookie"
            ).length;
            const cookieCount = cart.items.filter(
              (item) => data.find((s) => s._id === item)?.type === "cookie"
            ).length;
            const kawaiiCount = cart.items.filter(
              (item) => data.find((s) => s._id === item)?.type === "kawaii"
            ).length;

            if (product.type !== "cookie" && swissrollCount >= 2) {
              toast.error("Maximaal 2 swiss rolls per bestelling");
              return;
            }

            if (product.type === "kawaii" && kawaiiCount >= 1) {
              toast.error("Maximaal 1 kawaii swiss roll per bestelling");
              return;
            }

            if (product.type === "cookie" && cookieCount >= 5) {
              toast.error("Maximaal 5 cookies per bestelling");
              return;
            }

            setCart((prev) => {
              return {
                ...prev,
                items: [...prev.items, id],
              };
            });

            toast.success("Toegevoegd aan winkelwagen!");
          }}
          icon={<BiPlus size={16} />}
          text="Voeg toe"
        /> */}
      </div>
    </>
  );
};

export default Menu;
