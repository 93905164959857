import React from "react";

const faq = [
  {
    question: "Hoe lang is een swiss roll houdbaar?",
    answer:
      "Twee tot maximaal drie dagen goed afgesloten in de koelkast. Maximaal 2 uur buiten de koeling.",
  },
  {
    question: "Bezorgen jullie?",
    answer:
      "Helaas is alleen afhalen mogelijk, wel op een afgesproken tijdstip. Graag bestellingen minimaal 2 dagen van tevoren plaatsen.",
  },
  {
    question: "Uit hoeveel plakken bestaat een swiss roll?",
    answer:
      "Een standaard swiss rol bestaat uit 8 plakken van circa 3 centimeter breed. Sommige swiss rolls worden niet gesneden vanwege de topping.",
  },
  {
    question: "Zijn de swiss rolls glutenvrij/lactosevrij?",
    answer:
      "Op dit moment zijn onze swiss rolls niet glutenvrij. Wellicht dat deze optie in de toekomst aan het assortiment wordt toegevoegd.\nIn de swiss rolls zitten melkproducten verwerkt en zijn daarom niet lactosevrij.",
  },
];

const Faq = () => {
  return (
    <section>
      <h1 className="title">Veelgestelde vragen</h1>
      <div className="mt-10 flex flex-col space-y-8">
        {faq.map((item, index) => (
          <div key={index}>
            <h2 className="text-xl mb-1">{item.question}</h2>
            <p className="">{item.answer}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Faq;
