import React, { useState } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { api, cartAtom, productDataAtom } from "../App";
import { useAtom, useAtomValue } from "jotai";
import { IoCloseOutline } from "react-icons/io5";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "../components/ui/Button";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FcCheckmark } from "react-icons/fc";
import { HiX } from "react-icons/hi";
const leftpad = (n) => (n < 10 ? "0" + n : n);
const Cart = () => {
  const [cart] = useAtom(cartAtom);
  const productData = useAtomValue(productDataAtom);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    let total = 0;
    cart.items?.forEach((item) => {
      const product = productData.find((i) => i._id === item) ?? {
        name: "Error",
        description: "Error",
        id: "Error",
        price: 0.0,
        type: "Error",
      };
      total += product.price;
    });
    setTotal(total);
  }, [cart, productData]);

  // Scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-md min-h-[100vh] py-24 md:py-24">
      <p className="flex items-center space-x-2 mb-6">
        <HiOutlineArrowNarrowLeft />{" "}
        <Link to="/" className="text-gray-500 hover:text-gray-700 text-sm">
          Terug naar de home pagina
        </Link>
      </p>
      <h1 className="title">
        Winkelwagen{" "}
        <span className="text-slate-600 text-2xl ml-2">
          ({cart.items?.length ?? 0})
        </span>
      </h1>

      <div className="mt-8 flex flex-col space-y-4">
        {cart.items?.map((item) => (
          <CartCard item={item} />
        ))}
      </div>
      {cart.items?.length === 0 && (
        <div className="">
          <p className="text-gray-500 text-lg">
            Je winkelwagen is leeg, ga naar de home pagina om producten te
            bestellen!
          </p>
        </div>
      )}

      {cart.items?.length !== 0 && (
        <>
          <div className="text-right mt-8">
            <p className="text-gray-800 text-xl">
              Totaal: &euro;{total?.toFixed(2) ?? "Error"}
            </p>
          </div>

          <div className="mt-16">
            <h1 className="title text-3xl">Bestellen</h1>
            <p>
              Na het plaatsen van de bestelling krijgt u een e-mail met een
              bevestiging van uw bestelling. Nadat wij uw bestelling hebben
              verwerkt zullen wij contact opnemen met u voor het verzenden van
              het betaalverzoek. Als dit betaalverzoek is gemachtigd, gaan we
              gelijk aan de slag met het maken van uw bestelling!
            </p>
            <p className="mt-3 font-medium text-primary-700/70">
              ⚠️ Bestel minimaal 2 dagen van tevoren
            </p>
          </div>

          <div className="mt-8">
            <CartForm />
          </div>
        </>
      )}
    </div>
  );
};

const CartForm = () => {
  const [cart, setCart] = useAtom(cartAtom);
  const [canSubmit, setCanSubmit] = useState(true);
  const [dateIsValid, setDateIsValid] = useState(false);
  const [invalidDates, setInvalidDates] = useState([]);

  const navigate = useNavigate();

  const { register, handleSubmit, watch, setValue } = useForm();
  const pickupDate = watch("pickupDate");

  useEffect(() => {
    const fetchInvalidDates = async () => {
      const p = await fetch(api("/invalidDates"));
      const data = await p.json();
      setInvalidDates(data);
    };

    fetchInvalidDates();
  }, []);

  useEffect(() => {
    if (
      pickupDate &&
      invalidDates &&
      invalidDates.some((d) => pickupDate.startsWith(d))
    ) {
      setDateIsValid(false);
    } else {
      setDateIsValid(true);
    }
  }, [pickupDate, invalidDates]);

  const onSubmit = (data) => {
    // alert(JSON.stringify(data));
    if (!dateIsValid) {
      toast.error("Sorry, deze datum is niet beschikbaar");
      return;
    }

    setCanSubmit(false);
    const pickupTime = data.pickupTime;
    delete data.pickupTime;

    const p = fetch(api("/order"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
        pickupDate: `${data.pickupDate} ${pickupTime}`,
        cart: cart.items,
      }),
    });

    const a = new Promise((resolve, reject) => {
      p.then((res) => res.json())
        .then((data) => {
          if (data.ok) {
            // alert("Bestelling geplaatst!");
            setCart((c) => ({ ...c, items: [] }));
            resolve();

            navigate("/thankyou");
          } else {
            // alert("Er is een fout opgetreden: " + data.message);
            console.log(data);
            reject(data.message);
          }

          setCanSubmit(true);
        })
        .catch((err) => {
          console.error(err);
          alert("Er is een fout opgetreden: " + err);

          setCanSubmit(true);
          reject();
        });
    });

    toast.promise(a, {
      loading: "Bestelling plaatsen...",
      success: "Bestelling geplaatst!",
      error: (e) => "Er is een fout opgetreden: " + e,
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col space-y-4 items-start justify-start"
      autoComplete="on"
    >
      <div>
        <label htmlFor="firstName">Voornaam</label>
        <input
          id="firstName"
          type="text"
          className="input"
          placeholder="Uw voornaam"
          {...register("firstName", {
            required: "Dit veld is verplicht",
            maxLength: 80,
          })}
        />
      </div>
      <div>
        <label htmlFor="lastName">Achternaam</label>
        <input
          id="lastName"
          type="text"
          className="input"
          placeholder="Uw achternaam"
          {...register("lastName", {
            required: "Dit veld is verplicht",
            maxLength: 100,
          })}
        />
      </div>
      <div>
        <label htmlFor="email">E-mail</label>
        <input
          id="email"
          type="text"
          className="input"
          placeholder="Uw e-mailadres"
          {...register("email", {
            required: "Dit veld is verplicht",
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
        />
      </div>
      <div>
        <label htmlFor="mobileNumber">Telefoonnummer</label>
        <input
          id="mobileNumber"
          type="tel"
          className="input"
          placeholder="Uw telefoonnummer"
          {...register("mobileNumber", {
            required: "Dit veld is verplicht",
            maxLength: 16,
            minLength: 4,
          })}
        />
      </div>

      <div>
        <label htmlFor="pickupDate">
          <i>Gewenste</i> afhaaldatum (niet gegarandeerd, min. 2 dagen van
          tevoren)
        </label>

        <Calendar
          className="mt-2 rounded-md"
          // +2 Days
          minDate={(() => {
            const d = new Date();
            d.setDate(d.getDate() + 2);
            return d;
          })()}
          calendarType="ISO 8601"
          tileDisabled={({ date }) => {
            const localISO = `${date.getFullYear()}-${leftpad(
              date.getMonth() + 1
            )}-${leftpad(date.getDate())}`;

            return invalidDates.some((d) => localISO.startsWith(d));
          }}
          tileContent={({ date }) => {
            const localISO = `${date.getFullYear()}-${leftpad(
              date.getMonth() + 1
            )}-${leftpad(date.getDate())}`;

            var minimumOrderDate = new Date();
            minimumOrderDate.setDate(minimumOrderDate.getDate() + 1);
            minimumOrderDate.setHours(0, 0, 0, 0);

            if (date <= minimumOrderDate) {
              return (
                <div className="text-red-500 w-full mx-auto grid place-content-center text-center"></div>
              );
            } else if (invalidDates.some((d) => localISO.startsWith(d))) {
              return (
                <div className="text-red-500 w-full mx-auto grid place-content-center text-center">
                  <HiX />
                </div>
              );
            } else {
              return (
                <div className="w-full mx-auto grid place-content-center text-center">
                  <FcCheckmark />
                </div>
              );
            }
          }}
          tileClassName={({ date }) => {
            return "text-black";
          }}
          onChange={(value) => {
            setValue(
              "pickupDate",
              `${value.getFullYear()}-${leftpad(
                value.getMonth() + 1
              )}-${leftpad(value.getDate())}`
            );
          }}
        />
        <input
          type="hidden"
          id="pickupDate"
          className="input"
          {...register("pickupDate", {
            required: true,
          })}
        />
        {pickupDate && (
          <p className="text-sm mt-1">
            Geselecteerde datum:{" "}
            <span className="font-semibold">
              {new Date(pickupDate).toLocaleDateString("nl-NL", {
                weekday: "long",
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </span>
          </p>
        )}
      </div>

      <div>
        <label htmlFor="pickupTime">
          <i>Gewenste</i> afhaaltijd
        </label>
        <input
          id="pickupTime"
          type="time"
          className="input"
          placeholder="Uw gewenste afhaaltijd"
          {...register("pickupTime", {
            required: "Kies een afhaaltijd",
          })}
        />
      </div>

      {pickupDate && !dateIsValid && (
        <div className="text-red-500">
          Sorry, deze datum is niet beschikbaar!
        </div>
      )}

      <div className="w-full">
        <label htmlFor="info">Extra informatie</label>
        <textarea
          id="info"
          className="input w-full max-w-[600px]"
          placeholder="Heeft u nog extra informatie voor ons?"
          rows={4}
          {...register("info", {
            maxLength: 500,
          })}
        />
      </div>

      <div className="pt-6">
        <Button
          onClick={() => {
            if (!pickupDate) {
              toast.error("Selecteer alstublieft een datum");
            }
          }}
          disabled={!canSubmit}
          type="submit"
          text="Plaats bestelling"
        />
      </div>
    </form>
  );
};

const CartCard = ({ item }) => {
  const [, setCart] = useAtom(cartAtom);

  const productData = useAtomValue(productDataAtom);
  const product = productData.find((i) => i._id === item) ?? {
    name: "Error",
    description: "Error",
    id: "Error",
    price: 0.0,
    type: "Error",
  };

  return (
    <div className="border-[1px] rounded-md overflow-hidden lg:flex items-center shadow">
      <img
        src={product.image}
        alt="Afbeelding niet beschikbaar"
        className="lg:max-w-[280px]"
      />
      <div className="px-6 lg:px-10 py-6 lg:py-2 grow">
        <p className="font-medium text-lg">{product.name ?? "Error"}</p>
        <p className="text-gray-500 text-base">
          {product.description ?? "Error"}
        </p>
        <p className="mt-2 text-gray-800">
          &euro;{product.price?.toFixed(2) ?? "Error"}
        </p>
      </div>
      <button
        className="text-gray-500 hover:text-red-700 text-sm basis-1/5 p-6 text-right lg: pr-8"
        onClick={() => {
          setCart((prev) => {
            // Only remove the first occurence of the item
            const index = prev.items.indexOf(item);
            if (index > -1) {
              prev.items.splice(index, 1);
            }
            return { ...prev };
          });

          toast.success("Verwijderd uit winkelwagen");
        }}
      >
        <IoCloseOutline className="inline-block" /> Verwijderen
      </button>
    </div>
  );
};

export default Cart;
