import React from "react";
import Hero from "../components/header/Hero";
import Navbar from "../components/header/Navbar";
import { Element } from "react-scroll";
import AboutUs from "../components/sections/AboutUs";
import Menu from "../components/sections/Menu";
import Reviews from "../components/sections/Reviews";
import Faq from "../components/sections/Faq";
import Contact from "../components/sections/Contact";
import Bestellen from "../components/sections/Bestellen";

const Root = () => {
  return (
    <div className="">
      <Element name="home">
        <section>
          <Navbar />
          <div className="h-20"></div>
          <Hero />
        </section>
      </Element>

      <div className="container-md flex flex-col z-10 space-y-24 py-24">
        <Element name="about-us">
          <AboutUs />
        </Element>

        <Element name="Bestellen">
          <Bestellen />
        </Element>

        <Element name="menu">
          <Menu />
        </Element>

        <Element name="reviews">
          <Reviews />
        </Element>
        <Element name="faq">
          <Faq />
        </Element>
        <Element name="contact">
          <Contact />
        </Element>
      </div>

      <img
        className="absolute -bottom-[300px] sm:-bottom-[350px] md:-bottom-[250px] w-[50%] md:w-auto -z-0 right-10 md:left-[65%]"
        src="/images/totoro.png"
        alt=""
      />
    </div>
  );
};

export default Root;
