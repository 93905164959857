import React from "react";
import { AiOutlineMail, AiOutlineWhatsApp } from "react-icons/ai";

const Contact = () => {
  return (
    <section>
      <h1 className="title">Contact</h1>
      <p>Mimo's Bakery is uitsluitend bereikbaar via e-mail of WhatsApp:</p>
      <p className="flex items-center space-x-2">
        <AiOutlineMail />{" "}
        <a href="mailto:info@mimosbakery.nl">info@mimosbakery.nl</a>
      </p>
      <p className="flex items-center space-x-2">
        <AiOutlineWhatsApp /> <a href="https://wa.me/31619601315">WhatsApp</a>
      </p>
    </section>
  );
};

export default Contact;
