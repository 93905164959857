import React from "react";
import logo from "../logo.svg";

const NoMatch = () => {
  return (
    <div className="h-screen w-screen grid place-content-center">
      <div className="text-center flex flex-col items-center justify-center space-y-10">
        <img src={logo} alt="" />
        <h1 className="text-3xl text-primary-900">Deze pagina bestaat niet!</h1>
        <p className="text-lg text-gray-500">
          Ga terug naar de{" "}
          <a
            href="/"
            className="text-primary-900 hover:text-primary-800 transition-colors duration-300"
          >
            home pagina
          </a>
        </p>
      </div>
    </div>
  );
};

export default NoMatch;
