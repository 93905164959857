import React from "react";
import logo from "../../logo.svg";
import CircleButton from "../ui/CircleButton";
import { BsInstagram, BsFacebook } from "react-icons/bs";

const Hero = () => {
  return (
    <div
      style={{
        backgroundImage: `url(images/banner.jpeg)`,
        backgroundRepeat: "repeat-x",
      }}
      className="h-screen max-h-[400px] md:max-h-[600px] grid place-content-center bg-cover lg:bg-contain"
    >
      <img src={logo} alt="" className="w-[85%] mx-auto lg:w-[400px]" />
      <div className="mt-10 flex items-center justify-center gap-x-4 opacity-80">
        <CircleButton
          classNames="bg-gradient-to-r from-[#f093fb] to-[#f5576c]"
          onClick={() => {
            window.open("https://www.instagram.com/mimosbakery.nl/", "_blank");
          }}
        >
          <BsInstagram className="text-white text-2xl" />
        </CircleButton>
        <CircleButton
          classNames="bg-gradient-to-r from-[#48c6ef] to-[#6f86d6]"
          onClick={() => {
            window.open("https://www.facebook.com/mimosbakery.nl", "_blank");
          }}
        >
          <BsFacebook className="text-white text-2xl" />
        </CircleButton>
      </div>
    </div>
  );
};

export default Hero;
