import { atomWithLocalStorage } from "./helper/atomWithLocalStorage";
import { atom, useAtom } from "jotai";
import { toast, Toaster } from "react-hot-toast";
import Root from "./pages/Root";
import Cart from "./pages/Cart";

import { Routes, Route, BrowserRouter } from "react-router-dom";
import NoMatch from "./pages/NoMatch";
import { useEffect } from "react";
import ThankYou from "./pages/ThankYou";
import Admin from "./pages/Admin";

export const sessionAtom = atomWithLocalStorage("@mimosbakery/session", {
  token: null,
});

export const cartAtom = atomWithLocalStorage("cart/v0", {
  items: [],
});

export const productDataAtom = atom([]);

export const api = (str) => {
  let url;
  switch (process.env.NODE_ENV) {
    case "production":
      url = "https://api.mimosbakery.nl/api" + str;
      break;
    case "development":
    default:
      url = "http://localhost:3131/api" + str;
  }

  return url;
};

function App() {
  const [, setProducts] = useAtom(productDataAtom);

  useEffect(() => {
    fetch(api("/products"))
      .then((res) => res.json())
      .then((data) => {
        setProducts(data);
      })
      .catch((err) => {
        toast.error("Er is iets fout gegaan met het ophalen van de producten.");
        console.error(err);
      });
  }, [setProducts]);

  return (
    <BrowserRouter>
      <div className="bg-gray-50 relative overflow-hidden h-full">
        <Routes>
          <Route path="/">
            <Route index element={<Root />} />
            <Route path="cart" element={<Cart />} />
            <Route path="thankyou" element={<ThankYou />} />
            <Route path="admin" element={<Admin />} />

            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </div>
      <Toaster position="top-center" />
    </BrowserRouter>
  );
}

export default App;
