import React from "react";
import logo from "../logo.svg";

const ThankYou = () => {
  return (
    <div className="h-screen w-screen grid place-content-center max-w-[80%] mx-auto">
      <div className="text-center flex flex-col items-center justify-center space-y-5">
        <img src={logo} alt="" />
        <h1 className="text-3xl text-primary-900">
          Bedankt voor je bestelling!
        </h1>
        <p className="text-lg text-gray-800">
          Na het plaatsen van de bestelling krijgt u een e-mail met een
          bevestiging van uw bestelling.
          <br />
          Nadat wij uw bestelling hebben verwerkt zullen wij contact met u opnemen
          voor het verzenden van het betaalverzoek.
        </p>
        <br />
        <p className="text-base text-gray-500">
          Ga terug naar de{" "}
          <a
            href="/"
            className="text-primary-900 hover:text-primary-800 transition-colors duration-300"
          >
            home pagina
          </a>
        </p>
      </div>
    </div>
  );
};

export default ThankYou;
