import React, { useEffect } from "react";
import logo from "../logo.svg";
import { api, sessionAtom } from "../App";
import { useAtom } from "jotai";
import { toast } from "react-hot-toast";
import { FcCheckmark } from "react-icons/fc";
import { HiX } from "react-icons/hi";
import Calendar from "react-calendar";
import { useForm } from "react-hook-form";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

const leftpad = (n) => (n < 10 ? "0" + n : n);
const Admin = () => {
  const [password, setPassword] = React.useState("");
  const [session, setSession] = useAtom(sessionAtom);

  return session.token == null ? (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-32 w-auto" src={logo} alt="Your Company" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              onClick={async () => {
                try {
                  const res = await fetch(api("/admin/login"), {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ password }),
                  });

                  const data = await res.json();

                  if (data.ok !== true) {
                    throw new Error(data.message ?? "Unknown error");
                  }

                  setSession({ token: data.token });

                  toast.success("Je bent ingelogd!");
                } catch (error) {
                  console.error(error);
                  toast.error("Er is iets fout gegaan bij het inloggen.");
                }
              }}
            >
              Sign in
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <MainAdminPage />
  );
};

const MainAdminPage = () => {
  return (
    <div
      className="md:p-10 p-5 flex flex-col gap-y-6"
      style={{
        backgroundImage:
          "linear-gradient(to bottom, rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url('/totoro_bg.jpeg')",
        backgroundSize: "cover",
      }}
    >
      <h1 className="text-3xl">Mimo's Admin Panel</h1>
      <CalenderOverview />
      <MenuOverview />
    </div>
  );
};

const MenuOverview = () => {
  const [menu, setMenu] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [session] = useAtom(sessionAtom);

  useEffect(() => {
    fetchMenu();
  }, []);

  const fetchMenu = async () => {
    const res = await fetch(api("/products"));
    const data = await res.json();
    setMenu(data);

    const categories = data
      .map((p) => p.type)
      .filter((value, index, self) => self.indexOf(value) === index);

    setCategories(categories);
  };

  const onSave = async () => {
    const res = await fetch(api("/admin/setMenu"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.token}`,
      },
      body: JSON.stringify({ menu }),
    });

    const data = await res.json();

    if (data.ok !== true) {
      throw new Error(data.message ?? "Unknown error");
    }
  };

  const MenuCard = ({ product }) => {
    const [open, setOpen] = useState(false);

    const onSubmit = async (data) => {
      setMenu((menu) =>
        menu.map((p) => {
          if (p._id === product._id) {
            return {
              ...p,
              ...data,
              price: parseFloat(data.price.replace(/,/, ".")),
            };
          } else {
            return p;
          }
        })
      );
    };

    return (
      <>
        <div className="bg-white p-4 rounded flex flex-col items-start gap-2">
          <p className="font-medium">{product.name}</p>
          <img
            src={product.image}
            alt="Afbeelding niet beschikbaar"
            className="lg:max-w-[280px] rounded"
          />
          <div className="max-w-[280px] grow">
            <p className="">{product.description}</p>
          </div>

          <p>&euro; {product.price}</p>
          <div className="flex items-center gap-x-2">
            <button
              className="bg-slate-500 px-4 py-2 rounded text-white"
              onClick={() => setOpen(true)}
            >
              Aanpassen
            </button>
            <button
              className="bg-red-500 px-4 py-2 rounded text-white"
              onClick={() => {
                setMenu((menu) => menu.filter((p) => p._id !== product._id));
              }}
            >
              Verwijderen
            </button>
          </div>
        </div>
        <EditMenuItemModal
          product={product}
          open={open}
          setOpen={setOpen}
          onSubmit={onSubmit}
        />
      </>
    );
  };

  const EditMenuItemModal = ({ product, open, setOpen, onSubmit }) => {
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm();

    const [image, setImage] = React.useState(product.image);

    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <form
                    className="flex flex-col gap-y-2"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="flex flex-col gap-y-1">
                      <label htmlFor="name" className="text-sm font-medium">
                        Naam
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="rounded-md border-gray-300 shadow-sm"
                        defaultValue={product.name}
                        {...register("name", {
                          required: "Dit veld is verplicht",
                          minLength: {
                            value: 2,
                            message: "Dit veld moet minimaal 2 karakters zijn",
                          },
                          maxLength: {
                            value: 50,
                            message: "Dit veld mag maximaal 50 karakters zijn",
                          },
                        })}
                      />
                      {errors.name && (
                        <p className="text-red-500 text-sm">
                          {errors.name.message}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <label htmlFor="name" className="text-sm font-medium">
                        Afbeelding
                      </label>
                      <input
                        type="file"
                        className="rounded-md border-gray-300 shadow-sm"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          // Get base64
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            setImage(reader.result);
                            setValue("image", reader.result);
                          };
                          reader.onerror = (error) => {
                            console.error(error);
                          };
                          reader.readAsDataURL(file);
                        }}
                      />
                      <input
                        type="hidden"
                        id="image"
                        name="image"
                        defaultValue={image}
                        {...register("image", {
                          required: "Dit veld is verplicht",
                        })}
                      />

                      <img
                        src={image}
                        alt="Afbeelding niet beschikbaar"
                        className="lg:max-w-[280px] rounded"
                      />
                      {errors.image && (
                        <p className="text-red-500 text-sm">
                          {errors.image.message}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <label
                        htmlFor="description"
                        className="text-sm font-medium"
                      >
                        Beschrijving
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        className="rounded-md border-gray-300 shadow-sm"
                        defaultValue={product.description}
                        {...register("description", {
                          required: "Dit veld is verplicht",
                          minLength: {
                            value: 2,
                            message: "Dit veld moet minimaal 2 karakters zijn",
                          },
                          maxLength: {
                            value: 256,
                            message: "Dit veld mag maximaal 256 karakters zijn",
                          },
                        })}
                      />
                      {errors.description && (
                        <p className="text-red-500 text-sm">
                          {errors.description.message}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <label htmlFor="price" className="text-sm font-medium">
                        Prijs
                      </label>
                      <input
                        type="number"
                        id="price"
                        name="price"
                        className="rounded-md border-gray-300 shadow-sm"
                        defaultValue={product.price}
                        step="0.01"
                        {...register("price", {
                          required: "Dit veld is verplicht",
                          min: {
                            value: 0.01,
                            message: "Dit veld moet minimaal 0.01 zijn",
                          },
                        })}
                      />
                      {errors.price && (
                        <p className="text-red-500 text-sm">
                          {errors.price.message}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <label htmlFor="type" className="text-sm font-medium">
                        Type
                      </label>
                      <select
                        id="type"
                        name="type"
                        className="rounded-md border-gray-300 shadow-sm"
                        defaultValue={product.type}
                        {...register("type", {
                          required: "Dit veld is verplicht",
                        })}
                      >
                        {categories.map((category) => (
                          <option key={category} value={category}>
                            {category}
                          </option>
                        ))}
                      </select>
                      {errors.type && (
                        <p className="text-red-500 text-sm">
                          {errors.type.message}
                        </p>
                      )}
                    </div>

                    <div className="mt-5 sm:mt-6 flex items-center gap-x-2">
                      <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Opslaan
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                        onClick={() => setOpen(false)}
                      >
                        Annuleren
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const [newProductOpen, setNewProductOpen] = React.useState(false);
  const onSubmitNewProduct = async (data) => {
    setMenu((menu) => [
      ...menu,
      {
        ...data,
        _id: Math.random().toString(36).substr(2, 9),
        price: parseFloat(data.price.replace(/,/, ".")),
      },
    ]);

    setNewProductOpen(false);
  };

  return (
    <div className="p-5 border-[1px] rounded flex flex-col gap-y-2 border-black/50 bg-white/50">
      <p className="text-lg font-semibold">Menu Aanpassen</p>
      {categories.map((category) => (
        <div key={category}>
          <p className="text-lg font-medium capitalize">{category}</p>
          <div className="flex flex-wrap gap-4">
            {menu
              .filter((p) => p.type === category)
              .map((product) => (
                <MenuCard key={product._id} product={product} />
              ))}
          </div>
        </div>
      ))}

      <div className="flex items-center gap-x-2 mt-8">
        <button
          className="bg-primary-500 px-4 py-2 rounded text-white"
          onClick={() => {
            toast.promise(onSave(), {
              loading: "Opslaan...",
              success: "Opgeslagen!",
              error: (e) =>
                "Er is iets fout gegaan bij het opslaan: " + e.message,
            });
          }}
        >
          Opslaan
        </button>
        <button
          className="bg-slate-500 px-4 py-2 rounded text-white"
          onClick={() => setNewProductOpen(true)}
        >
          Nieuw product
        </button>
      </div>
      <EditMenuItemModal
        open={newProductOpen}
        setOpen={setNewProductOpen}
        product={{}}
        onSubmit={onSubmitNewProduct}
      />
    </div>
  );
};

const CalenderOverview = () => {
  const [session] = useAtom(sessionAtom);

  useEffect(() => {
    if (session.token != null) fetchInvalidDates();
  }, [session.token]);

  const [invalidDates, setInvalidDates] = React.useState([]);
  const [newDate, setNewDate] = React.useState(new Date());

  const fetchInvalidDates = async () => {
    const res = await fetch(api("/invalidDates"));
    const data = await res.json();
    // Sort the dates
    data.sort((a, b) => {
      return new Date(a) - new Date(b);
    });
    setInvalidDates(data);
  };

  return (
    <div className="p-5 border-[1px] rounded inline-block border-black/50 bg-white/50">
      <p className="text-lg font-semibold">
        Kalender Preview (wat de klant ziet)
      </p>
      <Calendar
        className="mt-2 rounded-md"
        // +2 Days
        minDate={(() => {
          const d = new Date();
          d.setDate(d.getDate() + 2);
          return d;
        })()}
        calendarType="ISO 8601"
        tileDisabled={({ date }) => {
          const localISO = `${date.getFullYear()}-${leftpad(
            date.getMonth() + 1
          )}-${leftpad(date.getDate())}`;

          return invalidDates.some((d) => localISO.startsWith(d));
        }}
        tileContent={({ date }) => {
          const localISO = `${date.getFullYear()}-${leftpad(
            date.getMonth() + 1
          )}-${leftpad(date.getDate())}`;
          if (invalidDates.some((d) => localISO.startsWith(d))) {
            return (
              <div className="text-red-500 w-full mx-auto grid place-content-center text-center">
                <HiX />
              </div>
            );
          } else {
            return (
              <div className="w-full mx-auto grid place-content-center text-center">
                <FcCheckmark />
              </div>
            );
          }
        }}
        tileClassName={({ date }) => {
          return "text-black";
        }}
        onChange={(value) => {}}
      />
      <div className="mt-4">
        <p className="text-lg font-semibold">Onbeschikbare datums</p>
        <div className="flex flex-wrap gap-4 items-center">
          {invalidDates?.map((d) => {
            // String to date
            const date = new Date(d);
            const dateStr = `${date.getFullYear()}-${leftpad(
              date.getMonth() + 1
            )}-${leftpad(date.getDate())}`;
            const currentDateMinusOneDay = new Date();
            currentDateMinusOneDay.setDate(
              currentDateMinusOneDay.getDate() - 1
            );
            if (date < currentDateMinusOneDay) {
              return null;
            }
            return (
              <div
                className="bg-white px-4 py-2 rounded flex items-center gap-x-2"
                key={dateStr}
              >
                <p>{dateStr}</p>
                <p
                  className="cursor-pointer hover:text-gray-500"
                  onClick={() => {
                    setInvalidDates((dates) =>
                      dates.filter((d) => d !== dateStr)
                    );
                  }}
                >
                  <HiX />
                </p>
              </div>
            );
          })}
        </div>
        <div>
          <p className="text-lg font-semibold mt-4">Datum toevoegen</p>
          <div className="flex gap-4 items-center">
            <input
              type="date"
              className="bg-white px-4 py-2 rounded"
              value={newDate.toISOString().split("T")[0]}
              onChange={(e) => {
                setNewDate(new Date(e.target.value));
              }}
            />
            <button
              className="bg-slate-500 px-4 py-2 rounded text-white"
              onClick={() => {
                setInvalidDates((dates) => {
                  const arr = [...dates, newDate.toISOString().split("T")[0]];

                  arr.sort((a, b) => {
                    return new Date(a) - new Date(b);
                  });

                  // Remove duplicates
                  return arr.filter(
                    (item, index) => arr.indexOf(item) === index
                  );
                });
              }}
            >
              Toevoegen
            </button>
          </div>
        </div>

        <div className="mt-4">
          <button
            className="bg-primary-500 px-4 py-2 rounded text-white"
            onClick={async () => {
              try {
                const res = await fetch(api("/admin/setInvalidDates"), {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${session.token}`,
                  },
                  body: JSON.stringify({ invalidDates }),
                });

                const data = await res.json();

                if (data.ok !== true) {
                  throw new Error(data.message ?? "Unknown error");
                }

                toast.success("De data is opgeslagen!");
                fetchInvalidDates();
              } catch (error) {
                console.error(error);
                toast.error("Er is iets fout gegaan bij het opslaan.");
              }
            }}
          >
            Opslaan
          </button>
        </div>
      </div>
    </div>
  );
};

export default Admin;
