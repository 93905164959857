import React from "react";

const Button = ({
  type = "button",
  icon = null,
  disabled = false,
  text,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className="rounded-lg border border-primary-300 bg-white px-5 py-2.5 text-center text-primary-700 shadow-sm transition-all hover:bg-primary-100/20 
      focus:ring focus:ring-primary-100 disabled:cursor-not-allowed disabled:border-primary-100 disabled:bg-primary-50 disabled:text-primary-400
      flex items-center justify-center space-x-2
      "
      disabled={disabled}
    >
      {icon}
      <p className="text-primary-900 font-normal text-base">{text}</p>
    </button>
  );
};

export default Button;
