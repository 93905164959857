import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ShoppingCartIcon } from "@heroicons/react/20/solid";
import { Link } from "react-scroll";
import { cartAtom } from "../../App";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";

const navigation = [
  { name: "Home", href: "home", current: false },
  { name: "Over ons", href: "about-us", current: false },
  { name: "Menu", href: "menu", current: false },
  { name: "Reviews", href: "reviews", current: false },
  { name: "Veelgestelde vragen", href: "faq", current: false },
  { name: "Contact", href: "contact", current: false },
];

export default function Navbar() {
  const [cart] = useAtom(cartAtom);
  const history = useNavigate();

  return (
    <Disclosure
      as="nav"
      className="p-2 bg-white/60 fixed w-full backdrop-blur-sm z-50"
    >
      {({ open }) => (
        <>
          <div className="container-md">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center">
                  <h1 className="font-semibold">MIMO'S BAKERY</h1>
                </div>
                <div className="hidden md:ml-6 lg:flex md:items-center md:space-x-2">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      className="text-slate-700 cursor-pointer transition-all underline underline-offset-8 decoration-transparent hover:text-gray-600 px-3 py-2 rounded-md"
                      activeClass="active"
                      to={item.href}
                      duration={500}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      isDynamic={true}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              {/* <div className="flex items-center">
                <div className="relative flex-shrink-0">
                  <button
                    onClick={() => {
                      history("/cart");
                    }}
                    className="relative inline-flex items-center rounded-md border border-transparent px-6 py-3 text-sm font-medium text-white shadow-sm banner-gradient"
                  >
                    <ShoppingCartIcon
                      className="lg:-ml-1 lg:mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden lg:block">Winkelwagen</span>
                  </button>
                  <div className="absolute -top-1 -left-2 rounded-full bg-red-600 text-white text-xs h-6 w-6 grid place-content-center">
                    {cart.items.length}
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3 flex flex-col">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={Link}
                  className="text-slate-700 cursor-pointer transition-all underline underline-offset-8 decoration-transparent hover:text-gray-600 px-3 py-2 rounded-md"
                  activeClass="active"
                  to={item.href}
                  duration={500}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  isDynamic={true}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
