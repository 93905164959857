import React from "react";
import { AiOutlineInstagram, AiOutlineWhatsApp } from "react-icons/ai";

const Bestellen = () => {
  return (
    <section className="">
      <h1 className="title">Bestellen</h1>
      <p>
        Om een bestelling te plaatsen, neem graag contact op via WhatsApp of
        Instagram:
      </p>

      <p className="flex items-center space-x-2">
        <AiOutlineWhatsApp />{" "}
        <a href="https://wa.me/31619601315" target="_blank" rel="noreferrer">
          WhatsApp (0619601315)
        </a>
      </p>

      <p className="flex items-center space-x-2">
        <AiOutlineInstagram />{" "}
        <a
          href="https://www.instagram.com/mimosbakery.nl/"
          target="_blank"
          rel="noreferrer"
        >
          Instagram (@mimosbakery.nl)
        </a>
      </p>
    </section>
  );
};

export default Bestellen;
